import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Project() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Data Analysis with GPT3"
              description="Fine-tuning GPT3 codex model that convert instructions to Python code to do data analysis. (Private)"
              // ghLink="https://github.com/soumyajit4419/Chatify"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Web with Cloud"
              description=" a website for analyzing and visualizing Kroger customers spending with million data points. Enabling an engine that can do data analysis on users' uploaded data"
              ghLink="https://github.com/minhN2000/AzureWeb"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Deep Reinforcment Learning"
              description="My trained flappy bird that can fly through the pipes by itself. This is where I self-learned about Deep Reinforcement Learning from scratch. Even though the average points are around 33 and the record is 66, the project helped me learned a lot about reinforcement learning"
              ghLink="https://github.com/minhN2000/Flappy_Bird_Deep_Q_Learning"
             // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Voice Biometric"
              description="A research project to enable voice biometric for University of Cincinnati website. My job was allow the engine capture voices, authenticate them with voice mismatch detector using a Java package name Dragon Naturally Speaking (Private) "
             // ghLink="https://github.com/soumyajit4419/Plant_AI"
             // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="AI for Image Retrieval"
              description="A researhc project where I was assigned to create a content-based image retrieval using ResNet and SIFT for an Android app named ReCDroid at UC (Private)"
              //ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Machine Learning Practice"
              description="This is the place where I practice implementing Machine Learning models from scratch. I have implemenented some basic Regressions, K-NN, SVM, Perceptron, FNN, and GNN"
              ghLink="https://github.com/minhN2000/LearningML"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Project;
