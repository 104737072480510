import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";


function HomeBelow() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container> 
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                        LET ME <span className="purple"> INTRODUCE </span> MYSELF
                        </h1>
                        <p className="home-about-body">
                        I fell in love with programming and I have at least learnt
                        something, I think… 🤷‍♂️
                        <br />
                        <br />I am fluent in classics like
                            <b className="purple"> Java, Javascript and Python. </b>
                        <br />
                        <br />
                        My field of Interest's are building new &nbsp;
                            <b className="purple">Web Technologies and Products </b> and
                            also in areas related to{" "}
                            <b className="purple">
                            Machine Learning and Deep Learning.
                            </b>
                        <br />
                        <br />
                        When I am free, I spend my time to explore new things in   
                        <b className="purple">Machine Learning</b> and
                            <b className="purple">
                            {" "}
                            Reinforcement Learning
                            </b>
                        &nbsp; like
                            <b className="purple"> Graph Neural Network and Stock Prediction</b>
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                        <img src={myImg} className="img-fluid" alt="avatar" />
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1>FIND ME ON</h1>
                        <p>
                        Feel free to <span className="purple">connect </span>with me
                        </p>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                                <a
                                href="https://github.com/minhN2000"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour  home-social-icons"
                                >
                                <AiFillGithub />
                                </a>
                            </li>
                            <li className="social-icons">
                                <a
                                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour  home-social-icons"
                                >
                                <AiOutlineTwitter />
                                </a>
                            </li>
                            <li className="social-icons">
                                <a
                                href="https://www.linkedin.com/in/minhn2000/"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour  home-social-icons"
                                >
                                <FaLinkedinIn />
                                </a>
                            </li>
                            <li className="social-icons">
                                <a
                                href="https://www.instagram.com/2550.m.20.5/"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour home-social-icons"
                                >
                                <AiFillInstagram />
                                </a>
                            </li>
                        </ul>
                    </Col>    
                </Row>    
            </Container>

        </Container>
    )
}

export default HomeBelow;